<route>
{
  "meta": {
    "permission": [
      "inventory.view_movement"
    ]
  }
}
</route>

<template>
  <i-table
    :title="$tc('movement', 2)"
    :headers="headers"
    app="inventory.movement"
    api="movement"
    @click:create="changeRoute({ pk: 'create' })"
    @click:edit="changeRoute"
  >
    <template v-slot:item.updated_date="{ item }">
      <span v-text="dateFormat(item.updated_date)"></span>
    </template>
    <template v-slot:extraToolbar>
      <v-divider class="mx-4" inset vertical></v-divider>
    </template>
  </i-table>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    headers() {
      return [
        {
          text: this.$tc('code', 2),
          value: 'own_prod'
        },
        {
          text: this.$tc('movement_type', 2),
          value: 'movement_type'
        },
        {
          text: this.$tc('quantity', 2),
          value: 'quantity'
        },
        {
          text: this.$t('updated_date'),
          value: 'updated_date'
        },
        {
          text: this.$t('updated_user'),
          value: 'own_user'
        },
        {
          text:
            this.movementBtn.delete || this.movementBtn.edit
              ? this.$t('action')
              : '',
          value:
            this.movementBtn.delete || this.movementBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    movementBtn() {
      return {
        delete:
          this.getPermissions([`inventory.delete_movement`]) || this.isAdmin,
        edit: this.getPermissions([`inventory.change_movement`]) || this.isAdmin
      }
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'movement-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    }
  }
}
</script>
